<template>
  <v-container fluid class="mb-10">
    <v-row style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-row class="pa-10">
            <v-col cols="12">
              <v-card class="mb-10" elevation="0">
                <v-data-table
                  :headers="UserDetailsHeader"
                  :items="itemsWithIndex"
                  :search="searchuser"
                  class="elevation-1"
                  :loading="loading"
                  loading-text="Please wait..."
                >
                  <template v-slot:top>
                    <v-toolbar
                      elevation="1"
                      style="background: #f5f5f5"
                      class="pa-0"
                    >
                      <div>
                        <p class="mb-0 text-h4">All Expenses</p>
                      </div>
                      <v-spacer></v-spacer>
                      <div>
                        <v-btn
                          color="primary"
                          elevation="0"
                          @click.prevent="dialog = true"
                          >Add Expense</v-btn
                        >
                      </div>
                    </v-toolbar>
                  </template>
                  <!-- <template v-slot:item.rank="{ index }">
                    {{ index + 1 }}
                  </template> -->
                  <template v-slot:item.status="{ item }">
                    <div v-if="item.is_active">Active</div>
                    <div v-else>Inactive</div>
                  </template>

                  <template v-slot:item.typeSlot="{ item }">
                    <div v-if="item.is_monthly">Monthly</div>
                    <div v-else>Daily</div>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      v-if="item.is_active"
                      shaped
                      text
                      @click="makeInactive(item)"
                      color="red"
                      style="padding-left: 0 !important"
                    >
                      Make inactive
                    </v-btn>

                    <v-btn
                      v-else
                      shaped
                      text
                      @click="makeActive(item)"
                      color="primary"
                      style="padding-left: 0 !important"
                    >
                      Make Active
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Add an expense -->
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog"
    >
      <v-card>
        <v-toolbar color="primary" dark>New Expense</v-toolbar>
        <v-card-text class="pb-0 pt-1">
          <v-col cols="12">
            <v-checkbox
              v-model="new_expense.is_monthly"
              color="secondary"
              hide-details
              class="mb-5"
            >
              <template v-slot:label>
                <div class="font-weight-bold text-h4">Monthly Expense ?</div>
              </template>
            </v-checkbox>
            <v-text-field outlined v-model="new_expense.name">
              <template v-slot:label>
                <p class="mb-0 font-weight-bold text-h4">Expense Name</p>
              </template>
            </v-text-field>
            <v-textarea outlined name="input-7-4" v-model="new_expense.note">
              <template v-slot:label>
                <p class="mb-0 font-weight-bold text-h4" >Note</p>
              </template>
            </v-textarea>
          </v-col>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="red" @click="dialog = false" elevation="0">Close</v-btn>
          <v-btn
            color="primary"
            @click="addNewExpense"
            elevation="0"
            :disabled="!new_expense.name.trim() || !new_expense.note.trim()"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    text: "",
    loading: true,
    snackbar: false,
    searchuser: "",
    search: "",
    color: "",
    text: "",

    UserDetailsHeader: [
      { text: "S/N", value: "index" },
      {
        text: "Expense Title",
        value: "expense_name",
        sortable: false,
        align: "start",
      },
       {
        text: "Expense Cycle",
        value: "typeSlot",
        sortable: false,
        align: "start",
      },
      { text: "Status", value: "status", sortable: false, align: "center" },
      { text: "Action", value: "actions", sortable: false, align: "center" },
    ],
    UserArray: [],

    dialog: false,
    new_expense: {
      is_active: true,
      is_monthly: false,
      note : '',
      name : ''
    },
  }),

  computed: {
    itemsWithIndex() {
      return this.UserArray.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },

  methods: {
    initialize() {
      axios
        .get(`/manager/showexpense/`)
        .then((response) => {
          if (response.data.success) {
            this.UserArray = response.data.data.reverse();
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // Make Inactive
    makeInactive(item) {
      axios
        .post(`/manager/change_list_status/${item.id}/0`)
        .then((response) => {
          if (response.data.success) {
            this.createSnackbar(response.data.message);
            let element = this.UserArray.find(({ id }) => id == item.id);
            element.is_active = false;
          } else {
            this.createSnackbar(response.data.message, "error");
          }
        })
        .catch((err) => {
          this.createSnackbar("Something went wrong !", "error");
        });
    },

    // Make active
    makeActive(item) {
      axios
        .post(`manager/change_list_status/${item.id}/1`)
        .then((response) => {
          if (response.data.success) {
            this.createSnackbar(response.data.message);

            let element = this.UserArray.find(({ id }) => id == item.id);
            element.is_active = true;
          } else {
            this.createSnackbar(response.data.message, "error");
          }
        })
        .catch((err) => {
          this.createSnackbar("Something went wrong !", "error");
        });
    },

    async addNewExpense() {
      if (this.new_expense.name.trim() || this.new_expense.note.trim()) {
        try {
          let response = await axios.post(
            `/manager/addexpense/`,
            this.new_expense
          );

          if (response.data.success) {
            this.dialog = false;
            this.createSnackbar(response.data.message);

            let payload = {
              created_at: new Date(),
              expense_name: this.new_expense.name,
              id: response.data.data,
              is_active: true,
              is_monthly : this.new_expense.is_monthly
            };

            this.UserArray.unshift(payload);
          }
        } catch (error) {
          this.dialog = false;
          this.createSnackbar("Something went wrong", "warning");
        }
      } else {
        this.dialog = false;
        this.createSnackbar("Something went wrong", "warning");
      }
    },

    createSnackbar(text, color = "primary") {
      this.text = text;
      this.color = color;
      this.snackbar = true;
    },
  },

  mounted() {
    this.initialize();
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>


